import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Tap2Earn from './Tap2Earn';

ReactDOM.render(
  <React.StrictMode>
    <Tap2Earn />
  </React.StrictMode>,
  document.getElementById('root')
);
