import React, { useState, useEffect } from 'react';
import './Tap2Earn.css';
import { FaMicrochip, FaDatabase, FaRocket, FaUserFriends, FaMap } from 'react-icons/fa';

const Tap2Earn = () => {
    const [userId, setUserId] = useState(null);
    const [coins, setCoins] = useState(0);
    const [energy, setEnergy] = useState(1500);
    const [animateCoins, setAnimateCoins] = useState(false);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const telegramId = urlParams.get('telegramId');

        if (telegramId) {
            console.log('Telegram ID from URL:', telegramId);
            setUserId(telegramId);

            // Send the telegramId to the server to log in or register the user
            fetch('/api/users/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId: telegramId }),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log('Login response:', data);
                    if (data.token) {
                        setCoins(data.user.coins);
                        setEnergy(data.user.energy);
                    }
                })
                .catch((error) => {
                    console.error('Error during login:', error);
                });
        } else {
            console.error('Telegram ID not found in URL');
        }
    }, []);

    const handleEarn = () => {
        if (energy > 0) {
            const newCoins = coins + 1;
            const newEnergy = energy - 1;

            setCoins(newCoins);
            setEnergy(newEnergy);
            setAnimateCoins(true);

            // Save the updated coins and energy to the database
            fetch('/api/users/update', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId, coins: newCoins, energy: newEnergy }),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log('Update response:', data);
                    if (data.success) {
                        console.log('Coins and energy updated successfully');
                    } else {
                        console.error('Failed to update coins and energy');
                    }
                })
                .catch((error) => {
                    console.error('Error updating coins and energy:', error);
                });

            setTimeout(() => setAnimateCoins(false), 1000); // Remove animation after 1 second
        }
    };

    return (
        <div className="tap2earn-container">
            <div className="header-icons">
                <div className="icon-container">
                    <FaMicrochip size={60} color="#ffcc00" />
                    <p>GPU POWER</p>
                </div>
                <div className="icon-container">
                    <FaDatabase size={60} color="#ffcc00" />
                    <p>DATA SERVER</p>
                </div>
            </div>

            <div className={`balance-container ${animateCoins ? 'animate' : ''}`}>
                <p>{coins.toLocaleString()}</p>
            </div>

            <div className="character-container">
                <button className="earn-button" onClick={handleEarn}>
                    Earn
                </button>
            </div>

            <div className="energy-container">
                <p>{energy}/1500</p>
            </div>

            <div className="navigation-bar">
                <button className="nav-button">
                    <FaRocket size={24} />
                    <p>Boost</p>
                </button>
                <button className="nav-button">
                    <FaUserFriends size={24} />
                    <p>Friends</p>
                </button>
                <button className="nav-button">
                    <FaMap size={24} />
                    <p>Quest</p>
                </button>
            </div>
        </div>
    );
};

export default Tap2Earn;
